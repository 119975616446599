.notify-container {
    min-height: 83vh;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    section {
        color: #E5E5E5;
        text-align: center;
        padding: 0 10%;
        line-height: 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .top-text {
            font-weight: 600;
            font-size: 18px;
            span {
                color: #C80000;
            }
        }
        > div {
            margin: 0 auto 4% auto;
        }
    }
    .email-input {
        margin-top: 6%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        .email-body {
            border: solid 3px rgb(54, 54, 54);
            padding: 12px;
            width: 100%;
            height: 90px;
            margin-bottom: 2%;
            border-radius: 5px;
            font-family: 'Roboto', sans-serif;
        }
        input {
            border: solid 3px rgb(54, 54, 54);
            padding: 12px;
            width: 100%;
            margin-bottom: 2%;
            border-radius: 5px;
        }
        .green-border {
            border: solid 3px green;
        }
        .red-border {
            border: solid 3px red;
        }
        .notification-text {
            color: #E5E5E5;
            font-size: 20px;
            height: 40px;
            margin-bottom: 30px;
        }
        button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            svg {
                margin-right: 20px;
            }
        }
    }
}

@media (min-width: 600px) {
    .notify-container {
        .email-input {
            .email-body {
                width: 50%;
            }
            input {
                width: 50%;
            }
        }
    }
}