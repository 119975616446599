body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, main,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  border-style: none;
  border: none; }

html {
  box-sizing: border-box; }

a {
  color: black; }

* {
  box-sizing: inherit; }

.app-container {
  min-height: 100vh; }
  .app-container .main-container {
    background: white; }

.centered-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between; }

.inner-container {
  min-height: 70vh;
  width: 90%; }

.white {
  background: #E5E5E5; }

.dark-grey {
  background: #262A2A; }

.light-grey {
  background: #484847; }

.action {
  color: #E5E5E5;
  background: #C80000;
  cursor: pointer;
  padding: 15px 23px;
  border-radius: 5px;
  box-shadow: 1px 2px 5px #262A2A;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease; }

.inaction {
  color: #E5E5E5;
  background: #363636;
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 5px;
  box-shadow: 1px 2px 5px #262A2A;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease; }

.disabled {
  color: #3d3d3d;
  background: #b8b8b8;
  cursor: not-allowed; }

.inaction:hover {
  background: #505050;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease; }

.action:hover {
  background: #aa0000;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease; }

.disabled:hover {
  background: #b8b8b8; }

.home-container {
  min-height: 83vh;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700; }
  .home-container section {
    min-height: 64vh;
    display: flex;
    padding: 3vh; }
    .home-container section .slick-dots {
      bottom: 2%; }
    .home-container section .slick-dots li button:before {
      font-size: 12px; }
    .home-container section .slick-slider {
      overflow-x: hidden;
      align-self: center; }
    .home-container section .slick-track {
      display: flex; }
    .home-container section .slick-slide > div:first-of-type {
      height: 100%;
      width: 100%; }
    .home-container section .slick-track .slick-slide {
      display: flex;
      height: auto;
      align-items: center;
      justify-content: center; }
    .home-container section .slick-list {
      border-radius: 10px; }
    .home-container section .product-panel {
      margin: 0 auto;
      height: 100%;
      width: 70vw;
      background: #484847;
      border-radius: 10px;
      display: flex;
      justify-content: space-between; }
      .home-container section .product-panel img {
        width: 40%;
        height: 100%;
        margin: 0 0 0 5%; }
      .home-container section .product-panel .panel-right > div:first-of-type {
        height: 100%;
        width: 100%; }
      .home-container section .product-panel .panel-right {
        padding: 5% 5% 5% 0;
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        text-align: center; }
        .home-container section .product-panel .panel-right .product-title {
          display: block;
          float: right;
          background: black;
          padding: 5% 7%;
          font-size: 30px;
          color: #C80000; }
        .home-container section .product-panel .panel-right .product-subtitle {
          display: block;
          float: right;
          background: #E5E5E5;
          font-family: 'Roboto', sans-serif;
          font-weight: 500;
          color: black;
          padding: 3% 7%;
          font-size: 20px; }
        .home-container section .product-panel .panel-right a {
          font-size: 20px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-right: 10%;
          border-radius: 5px;
          text-decoration: none; }
          .home-container section .product-panel .panel-right a svg {
            margin-right: 20px; }
  .home-container .home-bottom {
    color: #E5E5E5;
    background: black;
    height: 17vh;
    text-align: center;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .home-container .home-bottom div {
      font-size: 24px;
      margin-bottom: 20px; }
    .home-container .home-bottom a {
      font-family: 'Roboto', sans-serif;
      color: #E5E5E5;
      font-weight: 500; }

@media (max-width: 600px) {
  .centered-container section .product-panel {
    width: 85vw;
    padding: 0 0 5% 0;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .centered-container section .product-panel img {
      width: 80%;
      height: auto;
      margin: 0; }
    .centered-container section .product-panel .panel-right {
      width: 95%;
      padding: 0 5% 5% 5%; }
      .centered-container section .product-panel .panel-right div {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .centered-container section .product-panel .panel-right .product-title {
        font-size: 19px; }
      .centered-container section .product-panel .panel-right .product-subtitle {
        font-size: 14px;
        margin-bottom: 9%; }
      .centered-container section .product-panel .panel-right a {
        font-size: 18px;
        margin: 0 auto 2% auto;
        padding: 12px 19px; }
        .centered-container section .product-panel .panel-right a svg {
          width: 28px; }
  .centered-container .home-bottom div {
    font-size: 15px;
    margin-bottom: 20px; }
  .centered-container .home-bottom a {
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: 500; } }

.about-container {
  min-height: 83vh;
  padding-bottom: 7%;
  font-family: 'Open Sans', sans-serif; }
  .about-container .about-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 30px;
    font-weight: 600;
    height: 16vh; }
  .about-container article {
    font-family: 'Roboto', sans-serif;
    line-height: 1.5em;
    font-size: 17px;
    padding: 7% 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center; }
    .about-container article > div:first-of-type {
      margin-bottom: 25px; }
  .about-container .about-bottom {
    text-align: center;
    width: 30%;
    height: 16vh;
    padding: 2%;
    margin: 0 auto;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; }
    .about-container .about-bottom a {
      color: white;
      cursor: pointer; }

@media (max-width: 600px) {
  .about-top article {
    margin: 4% 9%; }
  .about-container .about-bottom {
    width: 90%; } }

.product-container {
  min-height: 83vh;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  color: #E5E5E5; }
  .product-container .inner-container {
    position: relative;
    margin: 2% auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 10px;
    padding: 3%; }
    .product-container .inner-container .store-back {
      position: absolute;
      top: 0;
      right: 0;
      margin: 2% 3.5%;
      text-decoration: none;
      font-size: 13px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
    .product-container .inner-container .product-images {
      display: flex;
      flex-direction: row;
      align-items: flex-start; }
      .product-container .inner-container .product-images .main-image {
        max-width: 80%; }
      .product-container .inner-container .product-images .image-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        margin-right: 2%; }
        .product-container .inner-container .product-images .image-column .hover-container {
          position: relative;
          margin-left: 2%;
          width: 100%; }
        .product-container .inner-container .product-images .image-column .hover-container:hover .overlay {
          opacity: 1; }
        .product-container .inner-container .product-images .image-column .overlay {
          position: absolute;
          bottom: 0;
          background: rgba(68, 68, 68, 0.5);
          width: 100%;
          height: 100%;
          -webkit-transition: .2s ease;
          transition: .2s ease;
          opacity: 0; }
        .product-container .inner-container .product-images .image-column .selected {
          background: rgba(46, 46, 46, 0.514);
          opacity: 1; }
        .product-container .inner-container .product-images .image-column .selected::after {
          content: "";
          position: absolute;
          left: 100%;
          margin-right: -10px;
          border-width: 10px;
          border-style: solid;
          border-color: transparent transparent transparent #b3b3b3; }
        .product-container .inner-container .product-images .image-column img {
          width: 100px;
          cursor: pointer; }
    .product-container .inner-container .product-description {
      margin: 2% 0 0 6%;
      min-width: 50%; }
      .product-container .inner-container .product-description > * {
        margin-bottom: 5%; }
      .product-container .inner-container .product-description > div:first-of-type {
        font-weight: 600;
        font-family: 'Open Sans', sans-serif;
        font-size: 25px; }
      .product-container .inner-container .product-description .product-flavor {
        width: 100%;
        color: black;
        font-size: 19px;
        padding: 3% 7%;
        line-height: 1.5em; }
      .product-container .inner-container .product-description ul {
        list-style-type: circle;
        line-height: 1.5em;
        margin-left: 1em; }
        .product-container .inner-container .product-description ul li {
          margin-bottom: 0.8em; }
      .product-container .inner-container .product-description .pricing {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .product-container .inner-container .product-description .pricing div {
          font-size: 29px; }
        .product-container .inner-container .product-description .pricing a {
          text-decoration: none;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center; }
          .product-container .inner-container .product-description .pricing a svg {
            margin-left: 18px; }

@media (max-width: 600px) {
  .product-container .inner-container {
    flex-direction: column; }
    .product-container .inner-container .store-back {
      top: auto;
      top: initial;
      bottom: 0;
      left: 0;
      margin: 4% 30%;
      text-align: center; }
    .product-container .inner-container .product-images {
      justify-content: center; }
      .product-container .inner-container .product-images .image-column img {
        width: 50px;
        cursor: pointer; }
      .product-container .inner-container .product-images .image-column .selected::after {
        content: "";
        position: absolute;
        left: 100%;
        margin-right: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent #b3b3b3; }
    .product-container .inner-container .product-description {
      margin: 6% 0 0 0; }
      .product-container .inner-container .product-description .product-flavor {
        font-size: 17px; }
        .product-container .inner-container .product-description .product-flavor > div {
          margin: 15px auto; }
      .product-container .inner-container .product-description .pricing {
        flex-direction: column; }
        .product-container .inner-container .product-description .pricing > div:first-of-type {
          margin-bottom: 15px; }
        .product-container .inner-container .product-description .pricing a {
          margin-bottom: 40px; }
    .product-container .inner-container .product-description > div:first-of-type {
      text-align: center; } }

.store-container {
  min-height: 83vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Open Sans', sans-serif; }
  .store-container .inner-container {
    padding: 0 10%; }
    .store-container .inner-container > div:first-of-type {
      color: #262A2A;
      width: 100%;
      margin: 4% auto;
      text-align: center;
      font-weight: 600;
      font-size: 26px; }
    .store-container .inner-container .category-header {
      color: white;
      padding: 1.5% 2.5%;
      font-weight: 700; }
    .store-container .inner-container a {
      text-decoration: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 400; }
    .store-container .inner-container .line-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: #black;
      padding: 1% 7%;
      background: transparent;
      -webkit-transition: 0.2s ease all;
      transition: 0.2s ease all; }
      .store-container .inner-container .line-item > div:first-of-type {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%; }
        .store-container .inner-container .line-item > div:first-of-type img {
          margin-right: 5%;
          width: 180px; }
    .store-container .inner-container .line-item:hover {
      cursor: pointer;
      color: white;
      background: #484847;
      -webkit-transition: 0.2s ease all;
      transition: 0.2s ease all; }
  .store-container .bottom-text {
    color: white;
    font-size: 19px;
    background: #484847;
    padding: 1% 2%;
    margin: 4%; }

@media (max-width: 600px) {
  .store-container .inner-container {
    width: 100%;
    padding: 0; }
    .store-container .inner-container > div:first-of-type {
      margin: 8% auto;
      font-size: 19px; }
    .store-container .inner-container .category-header {
      padding: 3% 4%; }
    .store-container .inner-container .line-item {
      font-size: 14px; }
      .store-container .inner-container .line-item > div:first-of-type {
        width: 70%; }
        .store-container .inner-container .line-item > div:first-of-type img {
          width: 90px; }
  .store-container .bottom-text {
    font-size: 13px;
    margin: 2%;
    padding: 3% 5%; } }

.notify-container {
  min-height: 83vh;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif; }
  .notify-container section {
    color: #E5E5E5;
    text-align: center;
    padding: 0 10%;
    line-height: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .notify-container section .top-text {
      font-weight: 600;
      font-size: 18px; }
      .notify-container section .top-text span {
        color: #C80000; }
    .notify-container section > div {
      margin: 0 auto 4% auto; }
  .notify-container .email-input {
    margin-top: 6%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%; }
    .notify-container .email-input .email-body {
      border: solid 3px #363636;
      padding: 12px;
      width: 100%;
      height: 90px;
      margin-bottom: 2%;
      border-radius: 5px;
      font-family: 'Roboto', sans-serif; }
    .notify-container .email-input input {
      border: solid 3px #363636;
      padding: 12px;
      width: 100%;
      margin-bottom: 2%;
      border-radius: 5px; }
    .notify-container .email-input .green-border {
      border: solid 3px green; }
    .notify-container .email-input .red-border {
      border: solid 3px red; }
    .notify-container .email-input .notification-text {
      color: #E5E5E5;
      font-size: 20px;
      height: 40px;
      margin-bottom: 30px; }
    .notify-container .email-input button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
      .notify-container .email-input button svg {
        margin-right: 20px; }

@media (min-width: 600px) {
  .notify-container .email-input .email-body {
    width: 50%; }
  .notify-container .email-input input {
    width: 50%; } }

.loading-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 11px; }

.loading-ellipsis div {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #d8d8d8;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.loading-ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: loading-ellipsis1 0.6s infinite;
          animation: loading-ellipsis1 0.6s infinite; }

.loading-ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: loading-ellipsis2 0.6s infinite;
          animation: loading-ellipsis2 0.6s infinite; }

.loading-ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: loading-ellipsis2 0.6s infinite;
          animation: loading-ellipsis2 0.6s infinite; }

.loading-ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: loading-ellipsis3 0.6s infinite;
          animation: loading-ellipsis3 0.6s infinite; }

@-webkit-keyframes loading-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes loading-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes loading-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes loading-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@-webkit-keyframes loading-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

@keyframes loading-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

.outer-container {
  height: 83vh;
  overflow: hidden; }

.svg-container {
  z-index: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top right, #484847 0 calc(50% - 4px), #c80000 calc(50% - 4px) calc(50% + 4px), #484847 calc(50% + 4px) 100%);
  width: 100%;
  height: 100%;
  position: relative; }

.small-slash {
  background: linear-gradient(to bottom right, #484847 0 calc(50% - 4px), #E5E5E5 calc(50% - 4px) calc(50% + 4px), #484847 calc(50% + 4px) 100%);
  width: 52%;
  height: 50%;
  position: absolute;
  margin: auto;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.width {
  top: 0;
  margin: 9px auto 0 auto;
  left: 0;
  right: 0; }

.height {
  -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
          writing-mode: vertical-rl;
  top: 0;
  bottom: 0;
  margin: auto 9px 0 auto;
  right: 0; }

.circle {
  z-index: 2;
  width: 40vw;
  height: auto;
  -webkit-animation: spin 12s linear infinite;
  animation: spin 12s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center; }

#centered {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3; }

.back-button {
  padding: 10px 15px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: #555555;
  font-size: calc(0.7vw + 1.2vh);
  font-weight: 700; }

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@media (max-width: 1024px) {
  .circle {
    width: 40vh; }
  #logo {
    width: 20vh; }
  .small-text {
    font-size: 10px; }
  #x-position {
    bottom: -9px; }
  #y-position {
    bottom: -9px;
    top: -9px; } }

@media (min-aspect-ratio: 16 / 9) {
  .circle {
    width: 40vw; }
  #logo {
    width: 20vw; } }

.nav-container {
  position: relative;
  font-family: 'Open Sans', sans-serif;
  background: black;
  display: flex;
  justify-content: space-between;
  min-height: 12vh;
  overflow-x: hidden; }
  .nav-container .logo-container {
    text-decoration: none;
    height: 90%;
    max-width: 30%;
    margin: auto 10%;
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #E5E5E5;
    font-size: 50px; }
    .nav-container .logo-container #axxion-logo {
      margin: auto;
      fill: #c80000;
      fill-opacity: 1;
      stroke: #e5e5e5;
      stroke-width: 4;
      stroke-linecap: butt;
      stroke-linejoin: miter;
      stroke-miterlimit: 4;
      stroke-dasharray: none;
      stroke-opacity: 1; }
    .nav-container .logo-container .title {
      margin-left: 5%;
      color: #E5E5E5;
      font-size: calc(2vw + 1.6vh); }
  .nav-container nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
    margin: auto 10%; }
    .nav-container nav .icon-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
    .nav-container nav .icon-container *:not(:last-child) {
      margin-right: 1vw; }
  .nav-container .drawer {
    position: absolute;
    z-index: 1;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 66vw;
    height: 100%;
    padding: 0 10%;
    background: #484847;
    box-shadow: -10px 0px 10px 1px #484847;
    -webkit-transform: translateX(calc(100% + 30px));
            transform: translateX(calc(100% + 30px));
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out; }
    .nav-container .drawer .nav-link {
      text-decoration: none;
      display: flex;
      font-weight: 500;
      font-size: calc(0.85vw + 0.7vh);
      justify-content: center;
      align-items: center;
      color: #E5E5E5;
      width: 100%;
      height: 100%;
      border: none;
      -webkit-transition: 0.2s all ease;
      transition: 0.2s all ease; }
    .nav-container .drawer .selected-nav {
      cursor: default;
      color: #797979;
      -webkit-transition: 0.5s all ease;
      transition: 0.5s all ease; }
  .nav-container .open {
    box-shadow: none;
    background: black;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out; }
  .nav-container .menu-lines {
    display: none; }
  .nav-container .banner-image {
    width: 70%;
    margin: 12px; }

@media (max-width: 600px) {
  .nav-container svg {
    width: 80%; }
  .nav-container .cart-icon {
    width: 70%; }
  .nav-container .drawer svg {
    width: 40px; }
  .nav-container .logo-container {
    margin: auto 5%; }
  .nav-container nav {
    margin: auto 5%; }
  .nav-container .drawer {
    justify-content: space-between;
    padding-left: 60px; }
    .nav-container .drawer .nav-link {
      width: auto;
      font-size: calc(1vw + 1.3vh); }
  .nav-container .open {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
    justify-content: space-between;
    width: 100vw; }
  .nav-container .banner-image {
    width: 100%; } }

footer {
  font-family: 'Roboto', sans-serif;
  background: #C80000;
  color: #cecece;
  padding: 0.5% 0;
  min-height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  footer a {
    color: #cecece; }
  footer .social-container {
    display: flex;
    justify-content: space-between;
    margin-left: 3%; }
    footer .social-container a {
      margin-right: 2vw; }
  footer .footer-text {
    margin-right: 3%;
    font-size: 12px; }

@media (max-width: 600px) {
  footer .social-container {
    width: 30vw; }
    footer .social-container svg {
      width: 20px; } }

