.product-container {
    min-height: 83vh;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    color: #E5E5E5;
    .inner-container {
        position: relative;
        margin: 2% auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 10px;
        padding: 3%;
        .store-back {
            position: absolute;
            top: 0;
            right: 0;
            margin: 2% 3.5%;
            text-decoration: none;
            font-size: 13px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        .product-images {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            .main-image {
                max-width: 80%;
            }
            .image-column {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;
                margin-right: 2%;
                .hover-container {
                    position: relative;
                    margin-left: 2%;
                    width: 100%;
                }
                .hover-container:hover .overlay {
                    opacity: 1;
                }
                .overlay {
                    position: absolute;
                    bottom: 0;
                    background: rgba(68, 68, 68, 0.5);
                    width: 100%;
                    height: 100%;
                    transition: .2s ease;
                    opacity: 0;
                }
                .selected {
                    background: rgba(46, 46, 46, 0.514);
                    opacity: 1;
                }
                .selected::after {
                    content: "";
                    position: absolute;
                    left: 100%;
                    margin-right: -10px;
                    border-width: 10px;
                    border-style: solid;
                    border-color: transparent transparent transparent rgb(179, 179, 179);
                }
                img {
                    width: 100px;
                    cursor: pointer;
                }
            }
        }
        .product-description {
            margin: 2% 0 0 6%;
            min-width: 50%;
            > * {
                margin-bottom: 5%;
            }
            > div:first-of-type {
                font-weight: 600;
                font-family: 'Open Sans', sans-serif;
                font-size: 25px;
            }
            .product-flavor {
                width: 100%;
                color: black;
                font-size: 19px;
                padding: 3% 7%;
                line-height: 1.5em;
            }
            ul {
                list-style-type: circle;
                line-height: 1.5em;
                margin-left: 1em;
                li {
                    margin-bottom: 0.8em;
                }
            }
            .pricing {
                font-family: 'Open Sans', sans-serif;
                font-weight: 600;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                div {
                    font-size: 29px;
                }
                a {
                    text-decoration: none;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    svg {
                        margin-left: 18px;
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .product-container {
        .inner-container {
            flex-direction: column;
            .store-back {
                top: initial;
                bottom: 0;
                left: 0;
                margin: 4% 30%;
                text-align: center;
            }
            .product-images {
                justify-content: center;
                .image-column {
                    img {
                        width: 50px;
                        cursor: pointer;
                    }
                    .selected::after {
                        content: "";
                        position: absolute;
                        left: 100%;
                        margin-right: -5px;
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent transparent transparent rgb(179, 179, 179);
                    }
                }
            }
            .product-description {
                margin: 6% 0 0 0;
                .product-flavor {
                    font-size: 17px;
                    > div {
                        margin: 15px auto;
                    }
                }
                .pricing {
                    flex-direction: column;
                    > div:first-of-type {
                        margin-bottom: 15px;
                    }
                    a {
                        margin-bottom: 40px;
                    }
                }
            }
            .product-description > div:first-of-type {
                text-align: center;
            }
        }
    }
}