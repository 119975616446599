footer {
    font-family: 'Roboto', sans-serif;
    // background: black;
    background: #C80000;
    // background: #262a2a;
    color: #cecece;
    padding: 0.5% 0;
    min-height: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
        color: #cecece;
    }
    .social-container {
        display: flex;
        justify-content: space-between;
        margin-left: 3%;
        a {
            margin-right: 2vw;
        }
    }
    .footer-text {
        margin-right: 3%;
        font-size: 12px;
    }
}

@media (max-width: 600px) {
    footer {
        .social-container {
            width: 30vw;
            svg {
                width: 20px;
            }
        }
    }
}