html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, main,
time, mark, audio, video {
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
box-sizing: border-box
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
display: block;
}
body {
line-height: 1;
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: '';
content: none;
}
table {
border-collapse: collapse;
border-spacing: 0;
}
button {
  border-style: none;
  border: none;
}
html {
box-sizing:border-box;
}
a {
  color: black;
}
* {
box-sizing:inherit
}

.app-container {
  min-height: 100vh;
  .main-container {
    background: white;
  }
}

.centered-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.inner-container {
  min-height: 70vh;
  width: 90%;
}

.white {
  background: #E5E5E5;
}

.dark-grey {
  background: #262A2A;
}

.light-grey {
  background: #484847;
}

.action {
  color: #E5E5E5;
  background: #C80000;
  cursor: pointer;
  padding: 15px 23px;
  border-radius: 5px;
  box-shadow: 1px 2px 5px #262A2A;
  transition: 0.2s ease;
}

.inaction {
  color: #E5E5E5;
  background: rgb(54, 54, 54);
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 5px;
  box-shadow: 1px 2px 5px #262A2A;
  transition: 0.2s ease;
}

.disabled {
  color: rgb(61, 61, 61);
  background: rgb(184, 184, 184);
  cursor: not-allowed;
}

.inaction:hover {
  background: rgb(80, 80, 80);
  transition: 0.2s ease;
}

.action:hover {
  background: rgb(170, 0, 0);
  transition: 0.2s ease;
}

.disabled:hover {
  background: rgb(184, 184, 184);
}
