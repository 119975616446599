.loading-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 11px;
}
.loading-ellipsis div {
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgb(216, 216, 216);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-ellipsis div:nth-child(1) {
    left: 6px;
    animation: loading-ellipsis1 0.6s infinite;
}
.loading-ellipsis div:nth-child(2) {
    left: 6px;
    animation: loading-ellipsis2 0.6s infinite;
}
.loading-ellipsis div:nth-child(3) {
    left: 26px;
    animation: loading-ellipsis2 0.6s infinite;
}
.loading-ellipsis div:nth-child(4) {
    left: 45px;
    animation: loading-ellipsis3 0.6s infinite;
}
@keyframes loading-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes loading-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes loading-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}
