.store-container {
    min-height: 83vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Open Sans', sans-serif;

    .inner-container {
        padding: 0 10%;
        > div:first-of-type {
            // color: white;
            color: #262A2A;
            width: 100%;
            margin: 4% auto;
            text-align: center;
            font-weight: 600;
            font-size: 26px;
        }
        .category-header{
            color: white;
            padding: 1.5% 2.5%;
            font-weight: 700;
        }
        a {
            text-decoration: none;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
        }
        .line-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // color: white;
            color: #black;
            padding: 1% 7%;
            background: transparent;
            transition: 0.2s ease all;
            > div:first-of-type {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                img {
                    margin-right: 5%;
                    width: 180px;
                }
            }
        }
        .line-item:hover {
            cursor: pointer;
            // color: black;
            color: white;
            // background: #E5E5E5;
            background: #484847;
            transition: 0.2s ease all;
        }
    }
    .bottom-text {
        // color: black;
        color: white;
        font-size: 19px;
        // background: #E5E5E5;
        background: #484847;
        padding: 1% 2%;
        margin: 4%;
    }
}

@media (max-width: 600px) {
    .store-container {
        .inner-container {
            width: 100%;
            padding: 0;
            > div:first-of-type {
                margin: 8% auto;
                font-size: 19px;
            }
            .category-header {
                padding: 3% 4%;
            }
            .line-item {
                font-size: 14px;
                > div:first-of-type {
                    width: 70%;
                    img {
                        width: 90px;
                    }
                }
            }
        }
        .bottom-text {
            font-size: 13px;
            margin: 2%;
            padding: 3% 5%;
        }
    }
}