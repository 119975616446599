.about-container {
    min-height: 83vh;
    padding-bottom: 7%;
    font-family: 'Open Sans', sans-serif;
    .about-top {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 30px;
        font-weight: 600;
        height: 16vh;
    }
    article {
        font-family: 'Roboto', sans-serif;
        line-height: 1.5em;
        font-size: 17px;
        padding: 7% 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        > div:first-of-type {
            margin-bottom: 25px;
        }
    }
    .about-bottom {
        text-align: center;
        width: 30%;
        height: 16vh;
        padding: 2%;
        margin: 0 auto;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        a {
            color: white;
            cursor: pointer;
        }
    }
}

@media (max-width: 600px) {
    .about-top {
        article {
            margin: 4% 9%;
        }
    }
    .about-container {
        .about-bottom {
            width: 90%;
        }
    }
}