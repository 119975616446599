.nav-container {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    background: black;
    display: flex;
    justify-content: space-between;
    min-height: 12vh;
    overflow-x: hidden;
    .logo-container {
        text-decoration: none;
        height: 90%;
        max-width: 30%;
        margin: auto 10%;
        display: flex;
        align-items: center;
        font-weight: 700;
        color: #E5E5E5;
        font-size: 50px;
        #axxion-logo {
            margin: auto;
            fill:#c80000;
            fill-opacity:1;
            stroke: #e5e5e5;
            stroke-width:4;
            stroke-linecap:butt;
            stroke-linejoin:miter;
            stroke-miterlimit:4;
            stroke-dasharray:none;
            stroke-opacity:1
        }
        .title {
            margin-left: 5%;
            // color: #c80000;
            color: #E5E5E5;
            font-size: calc(2vw + 1.6vh);
            // -webkit-text-stroke-width: 0.03em; 
            // -webkit-text-stroke-color: #e5e5e5; 
        }
    }
    nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 30%; 
        margin: auto 10%;
        .icon-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        .icon-container *:not(:last-child) {
            margin-right: 1vw;
        }
    }
    .drawer {
        position: absolute;
        z-index: 1;
        right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 66vw;
        height: 100%;
        padding: 0 10%;
        background: #484847;
        box-shadow: -10px 0px 10px 1px #484847;
        transform: translateX(calc(100% + 30px));
        transition: 0.5s all ease-in-out;
        .nav-link {
            text-decoration: none;
            display: flex;
            font-weight: 500;
            font-size: calc(0.85vw + 0.7vh);
            justify-content: center;
            align-items: center;
            color: #E5E5E5;
            width: 100%;
            height: 100%;
            border: none;
            transition: 0.2s all ease;
        }
        .selected-nav {
            cursor: default;
            color: rgb(121, 121, 121);
            transition: 0.5s all ease;
        }
    }

    .open {
        box-shadow: none;
        background: black;
        transform: translateY(0);
        transition: 0.5s all ease-in-out;
    }
    .menu-lines {
        display: none;
    }
    .banner-image {
        width: 70%;
        margin: 12px;
    }
}

@media (max-width: 600px) {
    .nav-container {
        svg {
            width: 80%;
        }
        .cart-icon {
            width: 70%;
        }
        .drawer svg {
            width: 40px;
        }
        .logo-container {
            margin: auto 5%;
        }
        nav {
            margin: auto 5%;
        }
        .drawer {
            justify-content: space-between;
            padding-left: 60px;
            .nav-link {
                width: auto;
                font-size: calc(1vw + 1.3vh);
            }
        }
        .open {
            transform: translateY(0);
            transition: 0.5s all ease-in-out;
            justify-content: space-between;
            width: 100vw;
        }

        .banner-image {
            width: 100%;
        }
    }
}