.home-container {
    min-height: 83vh;
    // background: #262a2a;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    section {
        min-height: 64vh;
        display: flex;
        padding: 3vh;
        .slick-dots {
            bottom: 2%;
        }
        .slick-dots li button:before {
            font-size: 12px;
        }
        .slick-slider {
            overflow-x: hidden;
            align-self: center;
        }
        .slick-track {
            display: flex;
        }
        .slick-slide > div:first-of-type {
            height: 100%;
            width: 100%;
        }
        .slick-track .slick-slide {
            display: flex;
            height: auto;
            align-items: center;
            justify-content: center;
        }
        .slick-list {
            border-radius: 10px;
        }
        .product-panel {
            margin: 0 auto;
            height: 100%;
            width: 70vw;
            background: #484847;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            img {
                width: 40%;
                height: 100%;
                margin: 0 0 0 5%;
            }
            .panel-right > div:first-of-type {
                height: 100%;
                width: 100%;
            }
            .panel-right {
                padding: 5% 5% 5% 0;
                width: 60%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
                text-align: center;
                .product-title {
                    display: block;
                    float: right;
                    background: black;
                    padding: 5% 7%;
                    font-size: 30px;
                    color: #C80000;
                }
                .product-subtitle {
                    display: block;
                    float: right;
                    background: #E5E5E5;
                    font-family: 'Roboto', sans-serif;
                    font-weight: 500;
                    color: black;
                    padding: 3% 7%;
                    font-size: 20px;
                }
                a {
                    font-size: 20px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-right: 10%;
                    border-radius: 5px;
                    text-decoration: none;
                    svg {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
    .home-bottom {
        color: #E5E5E5;
        // background: #262A2A;
        background: black;
        // background: #c80000;
        height: 17vh;
        text-align: center;
        padding: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
            font-size: 24px;
            margin-bottom: 20px;
        }
        a {
            font-family: 'Roboto', sans-serif;
            color: #E5E5E5;
            font-weight: 500;
        }
    }
}

@media (max-width: 600px) {
    .centered-container {
        section {
            .product-panel {
                width: 85vw;
                padding: 0 0 5% 0;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img {
                    width: 80%;
                    height: auto;
                    margin: 0;
                }
                .panel-right {
                    width: 95%;
                    padding: 0 5% 5% 5%;
                    div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .product-title {
                        font-size: 19px;
                    }
                    .product-subtitle {
                        font-size: 14px;
                        margin-bottom: 9%;
                    }
                    a {
                        font-size: 18px;
                        margin: 0 auto 2% auto;
                        padding: 12px 19px;
                        svg {
                            width: 28px;
                        }
                    }
                }
            }
        }
        .home-bottom {
            div {
                font-size: 15px;
                margin-bottom: 20px;
            }
            a {
                font-family: 'Roboto', sans-serif;
                font-size: 17px;
                font-weight: 500;
            }
        }
    }
}
