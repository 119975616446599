.outer-container {
    height: 83vh;
    overflow: hidden;
}

.svg-container {
z-index: 0;
margin: 0;
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: linear-gradient(to top right, #484847 0 calc(50% - 4px), rgb(200, 0, 0) calc(50% - 4px) calc(50% + 4px), #484847 calc(50% + 4px) 100%);
width: 100%;
height: 100%;
position: relative;
}

.small-slash {
    background: linear-gradient(to bottom right, #484847 0 calc(50% - 4px), #E5E5E5 calc(50% - 4px) calc(50% + 4px), #484847 calc(50% + 4px) 100%);
    width: 52%;
    height: 50%;
    position: absolute;
    margin: auto;
    z-index: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.width {
top: 0;
margin: 9px auto 0 auto;
left: 0;
right: 0;
}


.height {
writing-mode: vertical-rl;
top: 0;
bottom: 0;
margin: auto 9px 0 auto;
right: 0;
}

.circle {
z-index: 2;
width: 40vw;
height: auto;
-webkit-animation: spin 12s linear infinite;
-moz-animation: spin 12s linear infinite;
animation: spin 12s linear infinite;
display: flex;
justify-content: center;
align-items: center;
}

#centered {
position: absolute;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 3;
}


.back-button {
    padding: 10px 15px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    color: rgb(85, 85, 85);
    font-size: calc(0.7vw + 1.2vh);
    font-weight: 700;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

@media (max-width: 1024px) {
    .circle {
        width: 40vh;
    }
    #logo {
        width: 20vh;
    }
    .small-text {
        font-size: 10px;
    }
    #x-position {
        bottom: -9px;
    }
    
    #y-position {
        bottom: -9px;
        top: -9px;
    }
}

@media (min-aspect-ratio: 16/9) {
    .circle {
        width: 40vw;
    }
    #logo {
        width: 20vw;
    }
}
